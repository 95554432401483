@import '../tdi/css/lib';
@import 'tangelo-custom-clp.scss';
@import 'design-workarounds.scss';

/* default content tangelo.scss:*/
a {cursor:pointer;}
em {font-style:italic;}
tr {height:20px;}
section img {max-width:100%;height:auto;}

.marginbottom- {
  &none { margin-bottom:0; }
}
span.highlight {
  background-color:#d6ecff;
}

@each $list-style in (
  none, square, circle, disc,                                                       // ul list styles
  decimal, decimal-leading-zero, lower-alpha, upper-alpha, lower-roman, upper-roman // ol list-styles
) {
  .list-style-#{$list-style} {
    list-style: $list-style;
  }
}

.title-line {
   &.title-banner {
    background-repeat:no-repeat !important;
    background-size: cover !important;
    .container-fluid {
      position: relative !important;
      height: 200px !important;
      .container-title {
        position: absolute !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        left: 50% !important;
        width:100%;
      }
    }
  }
  &.title-quote h1{
    text-align: left !important;
  }
}
@media (min-width: 991px) {
  .title-line.title-banner .container-fluid {
    height: 302px !important;
  }
}

.items-about {
 margin-top: 70px;
}

.caption {
  font-style: italic;
}

.lang-zh .caption {
  font-style: normal;
}

.top-line a {
  color: #ffffff;
}

#data-table {
  h5 {
      color: #3ac2c0;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 15px;
  }
}
.wrap-single-studies .img {
    margin-bottom: 20px;
    margin-top: 20px;
}

.title-line.pad-md .container-fluid {
    padding-bottom: 90px;
}

table.data-table tr:first-of-type th:first-of-type p {
  display: none;
}

@media (max-width: 767px) {
  .title-line.pad-md .container-fluid {
      padding-top: 75px;
  }
  .title-line h1 {
    font-size: 28px;
  }
}

.main-menu li {
  vertical-align: top;

  a {
    height: 69px;
  }
}

.table-wrap.header-white th {
  color: #fff;
}

//ensure svgs get scaled up
.cms-image * {
  width: 100vw;
  max-width: 100%;
  margin-bottom: 30px;
}

// 2024
.icons a {color: black;}

/*
.item-sl:nth-child(-n+9) {
  display: inline-block ! Important;
}
*/
.nav-second {
  white-space: unset;
}
@media (max-width: 1199px) {
  .nav-second .container {
    white-space: unset;
  }
}
.image-content {
  margin-bottom: 30px;
}
.image-title {
  margin-bottom: 30px;
}

.ig-highlight {
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  color: #6FB3B9;
}

.targets-item dd:first-of-type {
  font-weight: normal;
}

.page-agenda {
  .ig-highlight {
    color: #769B53;
  }

  &.clp-energy {
    .ig-highlight {
      color: #6F6CA9;
    }
  }

  &.clp-digital {
    .ig-highlight {
      color: #3499CC;
    }
  }

  &.clp-community {
    .ig-highlight {
      color: #C2506D;
    }
  }

  &.clp-workforce {
    .ig-highlight {
      color: #E8A900;
    }
  }
}

.page-community {
  &.clp-community {
    .ig-highlight {
      color: #C2506D;
    }
  }
}

#highlights .col-md-6 {
  padding: 0;
}
#highlights .image-section {
  margin-top: 30px;
}
#highlights>.row {
  padding: 20px 0;
}
div#highlights {
  padding: 0;
  margin-top:15px;
  margin-bottom:50px;
}

div#highlights{
  margin-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 991px) {
  #highlights h2.font-lg {
    text-align: left;
  }
  #highlights>.row {
    padding: 0 20px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  #highlights>.row>*{
    margin-bottom: 10px;
  }

  #highlights .image-section{
    padding-bottom: 0;
    margin-top: 0;

  }
}

.page-nature .introduction, .page-nature .intro-n {
  color: #53923B;
}
.page-partner .introduction, .page-partner .intro-n {
  color: #469ECB;
}
.page-people .introduction, .page-people .intro-n {
  color: #A05D9D;
}
.page-customers .introduction, .page-customers .intro-n {
  color: #5EA8A2;
}

.page-customers .main-section-n h4 {
  color: #5EA8A2;
}
.page-people .main-section-n h4 {
  color: #A05D9D;
}
.page-partner .main-section-n h4 {
  color: #469ECB;
}
.page-community .main-section-n h4 {
  color: #C2506D;
}


.page-sustainability {
  .item-tabs-content-materiality {
    a.read-more-btn {
      color: white;
    }

    .read-more-btn i path {
      stroke: #ffffff;
    }
  }
  
}

.page-welcome .main-section-n .targets-item .title h2 {
  color: #ffffff;
}