// Workarounds for things that are broken in the supplied design - maybe some day these can be deleted... probably not

//show chinese link in header on mobile
.main-header-n .icons a {
  display: inline !important;
}

//Signature image scaling issues
.signature {
  max-width: initial !important;
  img {
    max-width: 425px;
  }
}

//make footer items line up
.main-footer .top-panel .wrap-nav-foot > ul > li > a {
  padding-right: 0px;

  @media screen and (min-width: 551px) {
    min-height: 70px;
  }
}

// fix borders being gone when rightmost column has merged cells
/*
.accordions-s .panel-body .table-wrap td:last-child {
  border-bottom: 1px solid #00479D;
}
.accordions-s .panel-body .table-wrap td.last-col {
  border-bottom: none;
}*/
