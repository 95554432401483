/* clp -specific changes: */
.table-wrap {
  table {
    table-layout: fixed;

    td,th {
      // font-weight: inherit !important;

      &[align=left], &.left {
        text-align: left !important;
      }
      &[align=center], &.center {
        text-align: center !important;
      }
      &[align=right], &.right {
        text-align: right !important;
      }
      &[valign=top], &.top {
        vertical-align: top !important;
      }
      &[valign=middle], &.middle {
        vertical-align: middle !important;
      }
      &[valign=bottom], &.bottom {
        vertical-align: bottom !important;
      }
      // &.image{
      //   padding-right: 0px;
      //   img {
      //     min-width:25px;
      //   }
      // }

    }
  }
}

.table-wrap table.data-table {
  td:nth-of-type(2) {
    background: transparent !important;
    &.highlight {
      background-color: #e5f5f5 !important;
    }
  }
  tr:hover td {
    background-color: #e5f5f5 !important;
  }
}
td.highlight {
  background-color: #e5f5f5 !important;
}
.table-wrap table.data-table td.verified,
.table-wrap table.data-table tr:first-child td.verified,
td.verified {
  background-color: #FDC240 !important;
}

.wrap-tabs h2 {
    font-weight: 700;
}
table p, table li {
    line-height: inherit;
    margin-bottom: inherit;
}
.table-wrap table tr:hover {
  td {
   background-color: #e5f5f5 !important;
  }
}

.table-format-none {
  margin-top: 35px;
}

.lang-zh,.lang-zh-cn{
  body {
    font-family: 'Microsoft JhengHei','Roboto Condensed', sans-serif;
  }
  .main-header-n .icons {
    font-family: 'Roboto Condensed', sans-serif;
  }
}

.chart {
  min-width: 500px;
}

//unfortunately the design uses !important for background color so now it has to be used almost everywhere
@each $class  , $main-color in
  (tortoise   , #279BA5)
, (rouge      , #c2506d)
, (green2     , #4ca855)
, (grapepurple, #6c60a3)
{
  .#{$class}.table-wrap table {
    th {
      background-color: $main-color !important;
    }
    td.highlight {
      background-color: mix($main-color, white, 10%) !important;
    }
  }
}

// Highcharts checkboxes
label.select-all {
  display: flex;
  align-items: center;
  padding: 0 0 0 0;
  margin: -1px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  line-height: normal;
  color: #000000;
  text-indent: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.unchecked {
    color: #CCCCCC;
  }

  input {
    position: relative;
    top: 0;
  }
}

input.highcharts-legend-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.5px solid #B4B4B4;
  outline: none;
  cursor: pointer;
  position: relative;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:checked::after {
    content: '\2714\FE0E';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 19px;
  }
}
